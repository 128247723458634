import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Etsy(props: Props) {
  const { onComplete, clientSecret } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const organizationId = store.get("orgId");
  const autoPlatform = store.get("autoPlatform");
  const [website, setWebsite] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    // skip oauth and get fake credentials
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SQUARE,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        // NEED OT PASS THIS BACK SOMEHOW
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    // redirect to oauth
    setLoading(true);
    const q = qs.stringify({
      ...extractLinkParamsFromState(store),
      store_url: website,
    });
    persistLinkState(STORES.SHOPER, store.getState());

    const result = axios
      .get(`${getBackendUrl()}/shoper/proxy/${organizationId}/app-url?${q}`)
      .then((result) => {
        const { url } = result.data;
        window.location.replace(url);
        // setLoading(false);
      })
      .catch((e) => {
        setError(
          e.response?.data?.error_message ??
            "Unknown error occurred. Please contact support@rutterapi.com",
        );
        setLoading(false);
      });
    // const url = `https://${website}.shoparena.pl/admin/plugin/execute/plugin/AppStore-Applications/act/show/app/4165516e6b04a6511a0ca1d13b99edbc`;
    // window.location.replace(url);
  };

  const isCustomShoperInstructions =
    organizationId === "f5d02be4-b0a0-458f-9bd2-e193b66be3b5";

  return (
    <div style={{ padding: "1.6rem" }}>
      {!autoPlatform && (
        <div style={{ display: "flex" }} className="">
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        </div>
      )}
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/shoperlogosquare.png"
          alt=""
        />
      </div>

      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Shoper Admin subdomain. If your subdomain is
        shop.shoparena.pl, then enter in "shop".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="http://"
          addonAfter=".shoparena.pl"
          placeholder="Store URL"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      {/* <div className="text-center pb-4"></div> */}
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Shoper"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || website === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Shoper"}
        </Button>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Etsy;
