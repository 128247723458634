import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Steps, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;
const DEFAULT_IMAGE_WIDTH = 600;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function QuickbooksDesktop(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const organizationName = stateStore.get("orgName");
  const autoPlatform = stateStore.get("autoPlatform");
  const [form] = Form.useForm();
  const [validationResponse, setValidationResponse] = useState([]);
  const [itemId, setItemId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyIdStatus, setCompanyIdStatus] = useState("...");
  const [currentProgressDot, setProgressDot] = useState(0);
  const [qwcFileDownloaded, setQwcFileDownloaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const companyIdSentString = "Successfully saved Account ID.";
  const isCompanyIdProvided = () => {
    return companyIdStatus === companyIdSentString;
  };

  // Keep updating the status of ProgressSteps based on what the user has done or is doing
  useEffect(() => {
    if (qwcFileDownloaded) {
      setProgressDot(7);
    } else if (isCompanyIdProvided()) {
      setProgressDot(2);
    } else if (companyId !== "") {
      setProgressDot(1);
    }
  }, [companyId, companyIdStatus, currentProgressDot, qwcFileDownloaded]);

  const errorRef = React.useRef<HTMLInputElement>(null);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFinish = (values: any) => {
    const { apiKey, password } = values;
    // try to post to backend
    setIsLoading(true);
    const finishPayload = {
      ...extractLinkParamsFromState(stateStore),
      link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
    };
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/link-verify`, finishPayload)
      .then((res) => {
        const { data } = res;
        const { is_successful, reason, public_token } = data;
        if (is_successful) {
          onComplete(public_token);
        } else {
          setError(reason);
        }
      })
      .catch((e) => {
        const { checkPointResponse } = e.response.data;
        const setValidationResponseAndScroll = () => {
          setValidationResponse(checkPointResponse);
          errorRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        };
        checkPointResponse
          ? setValidationResponseAndScroll()
          : handleAuthError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGenerateQwc = () => {
    setIsLoading(true);
    persistLinkState(STORES.QUICKBOOKS_DESKTOP, stateStore.getState());
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/link`, {
        ...extractLinkParamsFromState(stateStore),
        link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
      })
      .then((res) => {
        const { data } = res;
        const { qwcContent, password } = data;
        const file = new File([qwcContent], "quickbooks.qwc", {
          type: "text/plain",
        });

        function download() {
          const link = document.createElement("a");
          const url = URL.createObjectURL(file);

          link.href = url;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
        download();
        setQwcFileDownloaded(true);
        setPassword(password);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Returns the provided company id to the backend.
   */
  const handleSendCompanyId = () => {
    setIsLoading(true);
    setCompanyIdStatus("Wait...");
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/save-id`, {
        ...extractLinkParamsFromState(stateStore),
        company_id: companyId.trim(),
      })
      .then(({ data }) => {
        const {
          is_successful: isSuccessful,
          reason: errorMessage,
          item_id,
        } = data;
        if (isSuccessful) {
          setItemId(item_id);
          setCompanyIdStatus(companyIdSentString);
          setError("");
          messageApi.open({
            type: "success",
            content: companyIdSentString,
          });
        } else {
          const errorString =
            errorMessage === "duplicate"
              ? `This account number is already being used with a different connection. Please contact ${stateStore.get(
                  "orgName",
                )} for help.`
              : errorMessage;
          setError(errorString);
          messageApi.open({
            type: "error",
            content: errorString,
          });
        }
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));
  };

  function renderStep(step: number) {
    if (step === 0) {
      return (
        <div>
          First, make sure you're on your desktop computer or laptop with
          Quickbooks Desktop running. You'll need access to your Quickbooks
          files to complete the setup.
        </div>
      );
    } else if (step === 1) {
      return (
        <div>
          <div>
            <Input
              placeholder="Customer Account #"
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value.trim());
              }}
            />
          </div>
          <div>
            <Button
              type="primary"
              className="mt-2"
              style={{ marginRight: "8px" }}
              disabled={loading || companyId === ""}
              onClick={handleSendCompanyId}
            >
              Submit
            </Button>
          </div>
          To find your Account ID, within Quickbooks Desktop please click on "My
          Company" on the left sidebar. Then click on the "Manage Your Account"
          button on the right. Your customer account number should appear under
          the "Account Details" heading.
        </div>
      );
    } else if (step === 2) {
      return (
        <div>
          <div>
            <b>
              {password === "" ? (
                <Link
                  onClick={handleGenerateQwc}
                  disabled={!isCompanyIdProvided()}
                >
                  Click here to download the .qwc file.
                </Link>
              ) : (
                password
              )}
            </b>
          </div>
          <br></br>
          <b>
            <Link
              onClick={() => {
                navigator.clipboard.writeText(password);
              }}
              disabled={!isCompanyIdProvided()}
            >
              Click here
            </Link>
          </b>{" "}
          to copy your unique password: {password}{" "}
        </div>
      );
    } else if (step === 3) {
      return (
        <div>
          Double click the "quickbooks.qwc" file you downloaded to open it in
          the Web Connector. Click "OK" to confirm and then select the option to
          enable syncing when Quickbooks is running.
        </div>
      );
    } else if (step === 4) {
      return (
        <div>
          <div>
            Click "File" in the Quickbooks Desktop Menu, then select "App
            Management", then choose "Update Web Services" to open the Web
            Connector. If you have an issue with this step,{" "}
            <Link
              href="https://quickbooks.intuit.com/learn-support/en-us/install-new-products/set-up-quickbooks-web-connector/00/185852"
              target="_blank"
            >
              visit this article
            </Link>{" "}
            for more instructions.
          </div>
          <img
            width={DEFAULT_IMAGE_WIDTH}
            src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/QBD1.png"
            alt=""
          />
        </div>
      );
    } else if (step === 5) {
      return (
        <div>
          In the web connector, find the {organizationName} app in the list and
          paste your password into the Password field. Click "Yes" to save it
          when prompted.
        </div>
      );
    } else if (step === 6) {
      return (
        <div>
          Select the application in the list by checking the box to the left.
          Then click the "Update Selected" button above.
        </div>
      );
    } else if (step === 7) {
      return (
        <div>
          <div>Almost done! Click below to verify your connection ⚡️</div>
          <div>
            <Button
              disabled={loading || !password || !isCompanyIdProvided()}
              className="mt-2"
              type="primary"
              onClick={handleFinish}
            >
              Verify Connection
            </Button>
          </div>
          <img
            width={DEFAULT_IMAGE_WIDTH}
            src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/qbdpermissions.png"
            alt=""
          />
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      style={{
        padding: "1.6rem 1rem 200px",
        minHeight: "100%",
        overflowY: "scroll",
      }}
    >
      {contextHolder}
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/quickbooks-logo.svg"
          alt=""
        />
      </div>
      <Steps direction="vertical" progressDot current={currentProgressDot}>
        <Step title="Open Quickbooks Desktop" description={renderStep(0)} />
        <Step
          title="Enter your Quickbooks customer account number"
          description={renderStep(1)}
        />
        <Step title="Download the file below" description={renderStep(2)} />
        <Step
          title="Open the file you downloaded"
          description={renderStep(3)}
        />
        <Step
          title="Open the Quickbooks Web Connector"
          description={renderStep(4)}
        />
        <Step title="Enter your password" description={renderStep(5)} />
        <Step title="Run the connection" description={renderStep(6)} />
        <Step title="Verify the connection" description={renderStep(7)} />
      </Steps>
      {error && (
        <Alert
          className="mb-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default QuickbooksDesktop;
