import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LinkComplete from "../LinkComplete";
import LinkContainer from "../LinkContainer";
import LinkError from "../LinkError";
import CompanySelect from "../accounting/CompanySelect";
import QuickbooksBankFeedsAuth from "../accounting/QuickbooksBankFeedsAuth";
import QuickbooksBankFeedsConsent from "../accounting/QuickbooksBankFeedsConsent";
import QuickbooksBankFeedsSuccess from "../accounting/QuickbooksBankFeedsSuccess";
import SageBankFeedsAuth from "../accounting/SageBankFeedsAuth";
import BigCommerceComplete from "../bigcommerce/LinkComplete";
import ConnectionContainer from "../container/ConnectionContainer";
import UpdateComplete from "../container/UpdateComplete";
import ShopifyCustomAppComplete from "../shopify/CustomAppLinkComplete";
import SandboxFakeAuth from "../stores/shared/SandboxFakeAuth";
import { STORES } from "../utils";
import WooCommerceComplete from "../woocommerce/LinkComplete";

export default function MainContainer() {
  return (
    <Router>
      <Switch>
        <Route exact path="/complete">
          <LinkComplete></LinkComplete>
        </Route>
        <Route exact path="/updatecomplete">
          <UpdateComplete></UpdateComplete>
        </Route>
        <Route exact path="/bigcommercecomplete">
          <BigCommerceComplete></BigCommerceComplete>
        </Route>
        <Route exact path="/shopifycustomcomplete">
          <ShopifyCustomAppComplete></ShopifyCustomAppComplete>
        </Route>
        <Route exact path="/woocommercecomplete">
          <WooCommerceComplete></WooCommerceComplete>
        </Route>
        <Route exact path="/dynamicscompanyselect">
          <CompanySelect platform={STORES.DYNAMICS365}></CompanySelect>
        </Route>
        <Route exact path="/zohobookscompanyselect">
          <CompanySelect platform={STORES.ZOHOBOOKS}></CompanySelect>
        </Route>
        <Route exact path="/sandboxauth">
          <SandboxFakeAuth></SandboxFakeAuth>
        </Route>
        <Route exact path="/sage_bank_feeds_auth">
          <SageBankFeedsAuth></SageBankFeedsAuth>
        </Route>
        <Route exact path="/qbo_bank_feeds_auth">
          <QuickbooksBankFeedsAuth></QuickbooksBankFeedsAuth>
        </Route>
        <Route exact path="/qbo_bank_feeds_consent">
          <QuickbooksBankFeedsConsent></QuickbooksBankFeedsConsent>
        </Route>
        <Route exact path="/qbo_bank_feeds_success">
          <QuickbooksBankFeedsSuccess></QuickbooksBankFeedsSuccess>
        </Route>
        <Route exact path="/error">
          <LinkError></LinkError>
        </Route>
        <Route path="/connection/:id">
          <ConnectionContainer></ConnectionContainer>
        </Route>
        <Route path="/connections/:id">
          <ConnectionContainer></ConnectionContainer>
        </Route>
        <Route>
          <LinkContainer></LinkContainer>
        </Route>
      </Switch>
    </Router>
  );
}
