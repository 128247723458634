import { Button } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl } from "../utils";

type Props = {};

function QuickbooksBankFeedsConsent(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { search, pathname } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { consent_challenge, orgId, errorMessage } = parsedSearch;

  let allowAccounts = false;
  let allowTransactions = false;

  const onChangeAccountsCheckbox = (e: CheckboxChangeEvent) => {
    allowAccounts = e.target.checked;
  };

  const onChangeTransactionsCheckbox = (e: CheckboxChangeEvent) => {
    allowTransactions = e.target.checked;
  };

  const handleComplete = async () => {
    setIsLoading(true);

    let scope = [];
    if (allowAccounts) scope.push("accounts");
    if (allowTransactions) scope.push("transactions");

    try {
      const response = await axios.post(
        `${getBackendUrl()}/quickbooks_bank_feeds_connector/auth/consent?challenge=${consent_challenge}&orgId=${orgId}`,
        {
          scope: scope,
        },
      );

      window.location.replace(response.data);
    } catch (e: any) {
      const error = e.response?.data?.error_message ?? "Something went wrong.";
      // TODO(planders): Figure out how to refresh component without reloading the page.
      // https://linear.app/rutter/issue/DEV-5780/refresh-quickbooksbankfeedsauth-with-error-message-without-reloading
      window.location.href = `${pathname}?login_challenge=${consent_challenge}&orgId=${orgId}&errorMessage=${error}`;
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 600 }}>
        <div className="mt-4 font-semibold" style={{ fontSize: "2.25rem" }}>
          Connect your bank
        </div>

        <div className="text-lg mt-4" style={{}}>
          One-Time Password (OTP)
        </div>
        <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
          {
            "Please check the following to allow Quickbooks access to your bank data."
          }
        </div>
        <div className="flex items-align list-disc mt-4">
          <Checkbox onChange={onChangeAccountsCheckbox}>Accounts</Checkbox>
          <Checkbox onChange={onChangeTransactionsCheckbox}>
            Transactions
          </Checkbox>
        </div>

        <div
          className="mt-1 font-thin tahiti"
          style={{ fontWeight: 200, color: "red" }}
        >
          {errorMessage}
        </div>
        <Button
          className="mt-3"
          type="primary"
          block
          disabled={isLoading}
          onClick={handleComplete}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default QuickbooksBankFeedsConsent;
