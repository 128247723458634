import { Button, Input } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl } from "../utils";

type Props = {};

function QuickbooksBankFeedsAuth(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { search, pathname } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { login_challenge, orgId, errorMessage } = parsedSearch;

  const [password, setPassword] = useState("");

  const handleComplete = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${getBackendUrl()}/quickbooks_bank_feeds_connector/auth/login?challenge=${login_challenge}&otp=${password}&orgId=${orgId}`,
      );

      window.location.replace(response.data);
    } catch (e: any) {
      const error = e.response?.data?.error_message ?? "Something went wrong.";
      // TODO(planders): Figure out how to refresh component without reloading the page.
      // https://linear.app/rutter/issue/DEV-5780/refresh-quickbooksbankfeedsauth-with-error-message-without-reloading
      window.location.href = `${pathname}?login_challenge=${login_challenge}&orgId=${orgId}&errorMessage=${error}`;
    }

    setPassword("");
    setIsLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 600 }}>
        <div className="mt-4 font-semibold" style={{ fontSize: "2.25rem" }}>
          Connect your bank
        </div>

        <div className="text-lg mt-4" style={{}}>
          One-Time Password (OTP)
        </div>
        <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
          {"Please enter your one-time password (OTP)."}
        </div>
        <div className="mt-2">
          <Input
            placeholder=""
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          className="mt-1 font-thin tahiti"
          style={{ fontWeight: 200, color: "red" }}
        >
          {errorMessage}
        </div>
        <Button
          className="mt-3"
          type="primary"
          block
          disabled={isLoading || !password}
          onClick={handleComplete}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default QuickbooksBankFeedsAuth;
