import { Alert, Button, Steps } from "antd";
import qs from "query-string";
import { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import Link from "antd/lib/typography/Link";

const { Step } = Steps;
const DEFAULT_IMAGE_WIDTH = 600;

type Dynamics365Props = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function isGatedExtensionOrg(orgId: string) {
  // pluto and payable only
  return [
    "a928ada1-f309-4e97-b00d-1e01c4b4c687",
    "a68fff30-c323-4d0b-a03a-819d82aceb5e",
  ].includes(orgId);
}

function Dynamics365({
  clientSecret,
  onBack,
  onCancel,
  onComplete,
}: Dynamics365Props) {
  const store = Store.useStore();
  const origin = store.get("origin");
  const nonce = store.get("nonce");
  const [error, setError] = useState<string>("");
  const [currentProgressDot, setProgressDot] = useState(3);
  const [loading, setIsLoading] = useState(false);
  const stateStore = Store.useStore();
  const orgId = stateStore.get("orgId");

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleComplete() {
    try {
      setIsLoading(true);
      persistLinkState(STORES.DYNAMICS365, store.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/dynamics/app-url?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!isGatedExtensionOrg(orgId)) {
      // Trigger the OAuth immediately
      handleComplete();
    }
  }, []);

  if (!isGatedExtensionOrg(orgId)) {
    return null;
  }
  // For dynamics for some use cases (write accounting) we need to ask people to install our .app file before proceeding with the OAuth
  return (
    <div
      style={{
        padding: "1.6rem 1rem 200px",
        minHeight: "100%",
        overflowY: "scroll",
      }}
    >
      <Steps direction="vertical" progressDot current={currentProgressDot}>
        <Step
          title="Open Business Central"
          description={
            <>
              <div>
                Log in to Business Central and click on the search button.
              </div>
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/dynamicsearchbutton.png"
                alt=""
              />
            </>
          }
        />
        <Step
          title="Go to Extension Management"
          description={
            <>
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/dynamicsextensionmanagement.png"
                alt=""
              />
            </>
          }
        />
        <Step
          title="Go to Manage - Upload Extension"
          description={
            <>
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/dynamicsuploadextension.png"
                alt=""
              />
            </>
          }
        />
        <Step
          title="Download the AccountLink extension."
          description={
            <Link
              href="https://rutterdocs.s3.us-east-2.amazonaws.com/AppConnect.app"
              onClick={() => setProgressDot(5)}
            >
              Click here to download our Business Central extension.
            </Link>
          }
        />
        <Step
          title="Deploy the extension"
          description={
            <>
              <div>
                Select the downloaded .app file, check the "Accept" toggle, and
                deploy.
              </div>
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/dynamicsacceptanddeploy.png"
                alt=""
              />
            </>
          }
        />
        <Step
          title="Finish connection"
          description={
            <div>
              <div>
                Please make sure the extension has successfully been installed.
              </div>
              <Button
                className="mt-2"
                type="primary"
                onClick={handleComplete}
                disabled={loading || currentProgressDot < 5}
              >
                {loading ? "Connecting" : "Connect"}
              </Button>
            </div>
          }
        />
      </Steps>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </div>
  );
}

export default Dynamics365;
