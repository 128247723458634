import qs from "query-string";
import { useHistory } from "react-router";

type Props = {};

function QuickbooksBankFeedsSuccess(props: Props) {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { code, scope } = parsedSearch;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 600 }}>
        <div className="mt-4 font-semibold" style={{ fontSize: "2.25rem" }}>
          You did it!
        </div>

        <div className="text-lg mt-4" style={{}}>
          Your code: {code}
        </div>

        <div className="text-lg mt-4" style={{}}>
          Valid scopes: {scope}
        </div>
      </div>
    </div>
  );
}

export default QuickbooksBankFeedsSuccess;
