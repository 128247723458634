import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Select } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl, STORES } from "../utils";
// import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  platform: STORES;
};

/*
This component allows people to choose which company/organization in an accounting system to connect to Rutter
The most important thing is that the query parameters contain a list of companyNames (so the user can select which one)
and an array of credentialIds (corresopnding to real Credentials in our backend that we've already created for each option)
The credentialsId and companyNames arrays are related by index, so companyNames[0] corresponds to credentialsId[0]
The select component just asks the user to choose which company to connect, and redirects again to the backend.
*/
function CompanySelect(props: Props) {
  const [credentialId, setCredentialId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { platform } = props;

  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const {
    companyNames,
    credentialIds,
  }: { companyNames: string[]; credentialIds: string[] } = parsedSearch;
  if (companyNames == null || companyNames.length === 0) {
    history.replace("/error");
    return null;
  }

  const handleComplete = () => {
    setIsLoading(true);
    const q = qs.stringify({
      ...parsedSearch,
      credentialid: credentialId,
    });
    let url;
    switch (platform) {
      case STORES.DYNAMICS365:
        url = `${getBackendUrl()}/dynamics/finish-company-select?${q}`;
        break;
      case STORES.ZOHOBOOKS:
        url = `${getBackendUrl()}/zohobooks/finish-company-select?${q}`;
        break;
      default:
        history.replace("/error");
        return null;
    }
    window.location.href = url;
    setIsLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 800 }}>
        <div className="font-semibold">
          Select the Company you would like to connect:
        </div>

        <Select
          showSearch
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          placeholder="Select Company"
          onSelect={(val: any) => setCredentialId(val as string)}
          defaultValue={undefined}
        >
          {companyNames.map((name, index) => {
            return (
              <Select.Option key={name} value={credentialIds[index]}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          block
          disabled={isLoading || !credentialId}
          onClick={handleComplete}
        >
          Connect
        </Button>
      </div>
    </div>
  );
}

export default CompanySelect;
